import { useMemo } from 'react';
import { useLocale } from './use-locale';

export function useWebsitePolicies() {
  const t = useLocale();

  return useMemo(() => {
    return [
      { path: '/doc/cookie-policy', title: t('Title_CookiePolicy') },
      {
        path: '/doc/website-terms-of-use',
        title: t('Title_WebsiteTermsOfUse'),
      },
      { path: '/doc/privacy-policy', title: t('Label_PrivacyPolicy') },
      { path: '/doc/terms-conditions', title: t('Label_TermsAndConditions') },
      // {
      //   path: '/doc/modern-slavery-statement',
      //   title: t('Title_ModernSlaveryStatement'),
      // },
      // {
      //   path: '/doc/your-package-rights',
      //   title: t('Title_YourPackageRights'),
      // },
      {
        path: '/doc/data-processing-agreement',
        title: t('Title_DataProcessingAgreement'),
      },
    ];
  }, [t]);
}
